/* 1. RESET */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  text-decoration: none;
  list-style: none;

  /* DEBUG */
  /* outline: 1px solid red; */
}
html,
body {
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
/* 2. GLOBAL VARS */
:root {
  --padding: 12vh;
  --minFontSize: 16px;
  --maxFontSize: 24px;
  --scaler: 1rem + 1vw;
  --type-margin: 1rem;

  --border-radius: 17px;

  --color-dark: #1d3557;
  --color-light: hsl(185, 55%, 93%);
  --color-mid: #a8dadc;
  --color-accent: rgb(230, 57, 70);
}
::selection {
  color: var(--color-dark);
  background-color: var(--color-accent);
}

/* 3. TYPOGRAPHY */
* {
  font-family: "Poppins", sans-serif;
}
html {
  font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
  font-size: 16px;
  color: var(--color-dark);
}
h1 {
  --minFontSize: 28px;
  --maxFontSize: 84px;
  --scaler: 3vw;
  font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));

  font-size: 36px;
  font-weight: bold;
  margin-bottom: var(--type-margin);
}
h2 {
  font-size: 36px;
  margin-bottom: 1.5rem;
  max-width: 20ch;
}
h4 {
  margin-bottom: var(--type-margin);
}
h3 {
  font-size: 28px;
  margin-bottom: var(--type-margin);
}
p,
.pre-head {
  margin-bottom: 3rem;
}
p,
.list {
  opacity: 0.67;
  line-height: 180%;
}
.body-text {
  max-width: 50ch;
}
.body-text a {
  color: inherit;
  background-color: inherit;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 4px;
  border-radius: 4px;
}
.body-text a:hover {
  color: inherit;
  background-color: rgba(255, 255, 255, 0.1);
}
.pre-head,
.section__head {
  opacity: 0.86;
  font-size: 14px;
  margin-bottom: 1.2rem;
  text-transform: uppercase;
}

.section__head {
  font-weight: normal;
}
.card {
  padding: 2rem;
  margin-left: -2rem;
}
.card__title {
  font-size: 24px;
}
/* 4. CONTAINERS */
.nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 7vw 1rem;
  z-index: 2;
}
.nav__links {
  font-size: 14px;
  gap: 0.5rem;
  opacity: 0.76;
  max-width: 35vw;
}
.wrapper {
  height: 100vh;
}
.container {
  position: relative;
  padding: 4rem;
  margin: 0 auto;
  width: 86vw;
  max-width: 1366px;
  display: grid;
  border-radius: 2rem;
  /* outline: 4px solid; */
  margin-bottom: 30vh;
}
.container--primary {
  height: min-content;
  background-color: var(--color-dark);
  box-shadow: 0 0 40px var(--color-dark);
  color: var(--color-light);
  z-index: 10;
}

.container--contrast {
  background-color: var(--color-dark);
  color: var(--color-light);
}
.container--contrast .inner:first-of-type {
  margin-bottom: 4rem;
}
.container--alt {
  position: relative;
  margin-top: 2rem;
  padding: 4rem 6rem;
  margin-bottom: 2rem;
  z-index: 1;
}

.container--alt .inner {
  display: flex;
  justify-content: space-between;
}
.container--alt::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 150%;
  background-color: #fff;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.295);
  z-index: -1;
  border-radius: inherit;
}
.container--alt .container__body,
.container--alt .section-image {
  padding: 0;
  margin: auto 0;
}

.container--contacts .container__body {
  width: 48%;
}
.inner {
  display: flex;
  gap: 2rem;
  border-radius: inherit;
  z-index: 2;
}

.inner--row {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  padding: 0 2rem;
  gap: 0;
}
.inner--row::before {
  content: "";
  position: absolute;
  top: -4rem;
  left: -4rem;
  background-color: rgba(0, 0, 0, 0.2);
  width: calc(100% + 8rem);
  height: calc(100% + 8rem);
  border-radius: inherit;
}
.inner__body {
  width: 60%;
  padding-right: 2rem;
  height: max-content;
}
.container:not(.container:last-child) {
  margin-bottom: 4rem;
}
.container--header {
  display: flex;
  align-items: end;
  overflow: unset;
  margin: auto;
  padding: 4rem 7vw 25vh;
  margin-bottom: 10rem;
  background: url("./influence.svg") no-repeat;
  background-size: contain;
  background-position: 18vw -30vh;
  background-size: 120vw 140vh;

  width: 100vw;
  height: 112vh;
  overflow: unset;
}
.hero {
  max-width: 40vw;
  /* max-width: 476px; */
  height: max-content;
  align-self: center;
  margin-top: 10vh;
  z-index: 2;
}
.hero__head {
  width: 15ch;
}
.hero__body {
  width: 80%;
  margin-bottom: 4rem;
  z-index: 2;
}
.img--header {
  position: absolute;
  right: 7vw;
  height: unset;
  width: unset;
  border: 2px solid green;
  background: transparent !important;
  transform: scale(2.2);
  /* object-fit: scale-down; */
  background-size: contain;
  background-size: unset;
  z-index: -1;
}

.container--contacts {
  position: relative;
  width: 80%;
  margin: 0 auto;
  display: flex;
  /* padding-top: 1rem;
  padding-bottom: 1rem; */
  transform: scale(1);
  margin-bottom: 30vh;
}
.shadow::before,
.container--contrast::before {
  content: "";
  position: absolute;
  background-color: var(--color-accent);
  left: 3%;
  top: -1.5%;
  width: 94%;
  height: 103%;
  border-radius: inherit;
  z-index: -20;
}
.shadow {
  position: absolute;
  top: 0;
  left: 0;
  background-color: inherit;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.cubes {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  width: 90%;
  gap: 2rem;
}
.cubes__item {
  --side: calc(4rem + 2vw);
  width: var(--side);
  height: var(--side);
  display: grid;
}
.cube__bg {
  display: flex;
  justify-content: center;
  place-items: center;
  text-align: center;
  /* background-color: var(--color-mid); */
  border-radius: 9px;
}
.btn svg {
  stroke: var(--color-light);
}
.container--list {
  margin: 4rem 0;
  display: block !important;
}
.container__body {
  display: block;
  flex-direction: column;
  gap: 1rem;
  padding: 0;
  margin: auto;
  height: max-content;
  z-index: 2;
}
.card-list {
  counter-reset: card;
}
.card {
  position: relative;
}
.card > * {
  z-index: 2;
}
.card::before {
  counter-increment: card;
  content: counter(card);
  color: var(--color-accent);
  font-size: 140px;
  font-weight: 600;
  position: absolute;
  top: 20%;
  left: -1%;
  z-index: 1;
  opacity: 0.2;
}
.card li {
  list-style-type: disc;
}
.container--footer {
  display: flex !important;
  grid-auto-flow: row;
  justify-content: space-between;
  margin: 0 auto;
}
.container--footer::before {
  content: "";
  position: absolute;
  left: -6rem;
  bottom: 0;
  width: 100vw;
  height: 8px;
  border-radius: 8px;
  background-color: var(--color-accent);
}
.list__head {
  margin-bottom: 1rem !important;
}
.footer__links {
  display: flex;
  gap: 4rem;
  height: min-content;
  z-index: 2;
}
.footer__body {
  z-index: 2;
}
.footer__links .list > * {
  display: block;
  margin-bottom: 8px;
}
/* 5. IMAGES & ICONS*/
.img {
  object-fit: cover;
}
.section-image {
  height: 500px;
  border-radius: var(--border-radius);
  object-fit: contain;
  z-index: 2;
}
.btn__icon {
  display: grid;
  align-items: center;
}

.icon {
  --size: 0.8rem;
  width: var(--size);
  height: var(--size);
}
.icon--chevron {
  transform: rotate(45deg);
  border: 2px solid transparent;
  border-bottom-color: var(--color-accent);
  border-right-color: var(--color-accent);
}
/* 6. BUTTONS & LINKS */
a {
  text-decoration: none;
}
.btn {
  outline: none;
  border: none;
  background-color: transparent;
  font-family: inherit;
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  width: max-content;
  height: max-content;
  padding: 4px 8px;
}
.btn--primary {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 3rem;
  border-radius: var(--border-radius);
  background-color: var(--color-accent);
  box-shadow: 0 0 40px var(--color-accent);
  color: var(--color-light);
}
.btn--link {
  color: var(--color-accent);
}
.link {
  position: relative;
  overflow: hidden;
  width: max-content;
  height: max-content;
  color: var(--color-dark);
  font-size: 1rem;
}
.btn__text {
  font-size: 1rem;
  font-weight: 500;
}

/* 6. FORMS & INPUTS */
form {
  position: relative;
}
.input {
  outline: none;
  border: none;
  padding: 1.6rem 2rem;
  width: 100%;
  border-radius: var(--border-radius);
  font-size: 16px;
  font-family: inherit;
  border: 2px solid transparent;
  transition: all ease-out 400ms;
}
.input:focus {
  border-color: var(--color-accent);
}
.btn--inline {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: var(--color-accent);
  box-shadow: 0 0 40px var(--color-accent);
  justify-content: center;
  align-items: center;
  z-index: 3;
  border-radius: var(--border-radius);
  font-weight: 500;
}
/* (N-3). INTERACTIONS */
.cubes__item,
.link,
.btn {
  transition: 400ms transform ease-out;
  will-change: auto;
}
.cube,
.link,
.btn {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.cube::before,
.link::before,
.btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 1rem;
  background-color: rgba(0, 0, 0, 0.05);
  width: 4px;
  height: 4px;
  transition: inherit;
  border-radius: 50%;
  z-index: -2;
}
.cube:hover::before,
.link:hover::before,
.btn:hover::before {
  transform: scale(20000%);
}
.section-image {
  transition: 10s all ease;
}
.section-image:hover {
  transform: scale(1.1);
  opacity: 0.8;
}
.img--header:hover {
  transform: scale(1.8);
}

/* (N-2). ABSTRACT */
.flex {
  display: flex;
}
.flex--fullwidth {
  justify-content: space-between;
  gap: 1rem;
}
.margin-bottom {
  margin-bottom: var(--type-margin);
}

/* (N-1). DESKTOP LAYOUT */
@media (min-width: 986px) {
  .nav__links {
    gap: 2rem;
  }
  .container {
    gap: 1rem;
  }
  .footer__links {
    grid-template-columns: 1fr 1fr;
  }

  .section-image {
    min-width: 360px;
    width: 400px;
    height: 450px;
    z-index: 3;
  }
}
